/* eslint-disable no-param-reassign */

import { APP } from '../../commons/reducer';
import { createAction, createReducer } from '../../utils/reducer';
// Local constants
const PATH = 'quote/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const CALCULATOR = {
  GET_QUOTE: `${PATH}GET_QUOTE`,
  CALCULATOR_SUCCESS: `${PATH}CALCULATOR_SUCCESS`,
  CALCULATOR_FAILED: `${PATH}CALCULATOR_FAILED`,
  GET_OCURRE_BRANCH_OFFICES: `${PATH}GET_EXPRESS_OCURRE_BRANCH_OFFICES`,
  GET_OCURRE_BRANCH_OFFICES_SUCCESS: `${PATH}GET_OCURRE_BRANCH_OFFICES_SUCCESS`,
  GET_OCURRE_BRANCH_OFFICES_FAILED: `${PATH}GET_OCURRE_BRANCH_OFFICES_FAILED`,
  GET_UPDATED_RATE_FOR_PICKUP: `${PATH}GET_UPDATED_RATE_FOR_PICKUP`,
  GET_UPDATED_RATE_FOR_PICKUP_SUCCESS: `${PATH}GET_UPDATED_RATE_FOR_PICKUP_SUCCESS`,
  GET_UPDATED_RATE_FOR_PICKUP_FAILED: `${PATH}GET_UPDATED_RATE_FOR_PICKUP_FAILED`,
};

// actions
export const calculatorActions = {
  calculatorFormChange: createAction(CONTROLS_CHANGED, 'calculatorForm'),
  // Calculator fuction
  getQuote: createAction(CALCULATOR.GET_QUOTE, 'calculatorForm'),
  calculatorSuccess: createAction(CALCULATOR.CALCULATOR_SUCCESS, 'result'),
  calculatorFailed: createAction(CALCULATOR.CALCULATOR_FAILED, 'result'),
  getOccureBranchOffices: createAction(CALCULATOR.GET_OCURRE_BRANCH_OFFICES, 'branchOffices'),
  getOccureBranchOfficesSucess: createAction(CALCULATOR.GET_OCURRE_BRANCH_OFFICES_SUCCESS, 'branchOfficesRes'),
  getOccureBranchOfficesFailed: createAction(CALCULATOR.GET_OCURRE_BRANCH_OFFICES_FAILED, 'branchOfficesRes'),
  getUpdatedRateForPickup: createAction(CALCULATOR.GET_UPDATED_RATE_FOR_PICKUP, 'updatedRateForPickup'),
  getUpdatedRateForPickupSuccess: createAction(CALCULATOR.GET_UPDATED_RATE_FOR_PICKUP_SUCCESS, 'updatedRateForPickup'),
  getUpdatedRateForPickupFailed: createAction(CALCULATOR.GET_UPDATED_RATE_FOR_PICKUP_FAILED, 'updatedRateForPickup'),
};

const initialState = {
  calculatorForm: {
    clientAddrOrig: {
      zipCode: '',
      colonyName: ''
    },
    clientAddrDest: {
      zipCode: '',
      colonyName: ''
    },
    amountOfEnvelope: 0,
    amountOfPackets: 0,
    shipmentPktDetails: [],
    shipmentEnvDetails: {},
    error: false,
    loading: false,
    origData: [],
    destData: [],
    shipmentSelect: {},
  },
  calculatorResult: [],
  branchOffices: {},
  branchOfficesRes: {
    success: {},
    failed: {}
  },
  updatedRateForPickup: {},
};

const calculator = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.calculatorForm = {
      ...state.calculatorForm,
      ...action.calculatorForm,
    };
  },
  [CALCULATOR.GET_QUOTE](state: any) {
    state.calculatorForm.loading = true;
  },
  [CALCULATOR.CALCULATOR_SUCCESS](state: any, action: any) {
    state.calculatorResult = action.result.data;
    state.calculatorForm.loading = false;
    state.calculatorForm.actionQuote = true
  },
  [CALCULATOR.CALCULATOR_FAILED](state: any,) {
    state.calculatorForm.error = true;
    state.calculatorForm.loading = false;
    state.calculatorForm.errorMessage = 'Oops, wrong credentials.';
  },
  [CALCULATOR.GET_OCURRE_BRANCH_OFFICES_SUCCESS](state: any, action: any) {
    state.branchOfficesRes.success = action.branchOfficesRes.data;
  },
  [CALCULATOR.GET_OCURRE_BRANCH_OFFICES_FAILED](state: any, action: any) {
    state.branchOfficesRes.failed = action.branchOfficesRes.data;
  },
  [CALCULATOR.GET_UPDATED_RATE_FOR_PICKUP](state: any, action: any) {
    state.updatedRateForPickup = action.updatedRateForPickup.data;
  },
  [CALCULATOR.GET_UPDATED_RATE_FOR_PICKUP_SUCCESS](state: any, action: any) {
    state.updatedRateForPickup = action.updatedRateForPickup.data;
  },
  [CALCULATOR.GET_UPDATED_RATE_FOR_PICKUP_FAILED](state: any, action: any) {
    state.updatedRateForPickup = {};
  },
  [APP.SERVER_FAILED](state: any) {
    state.calculatorForm.loading = false;
  },
});

export default calculator;