import React from 'react';

const QuoteResume = ({ calculatorForm, profile, newShipmentData }: any) => {
  const totalPkt = calculatorForm.shipmentPktDetails?.reduce(
    (carry: number, item: any) => (carry += item.quantity),
    0,
  );
  const shouldShowHover =
    newShipmentData &&
    newShipmentData.priceWithDiscount !== calculatorForm?.shipmentSelect.priceWithDiscount;
  return (
    <div className='md:space-x-4 px-2'>
      <h3 className='text-2xl font-bold md:space-x-4'>Resumen:</h3>
      <hr className='mt-1 mb-4 md:min-w-full ' />
      <div className='grid grid-cols-1 md:grid-cols-7 space-x-4 mb-8'>
        <div className='rounded-xl shadow-xl p-4 bg-white h-30 shadow-sm'>
          <div className='flex h-full'>
            <div className='m-auto'>
              <span className='font-extrabold text-xs uppercase text-black'>
                Servicio:
              </span>
              <p className='text-xl text-black'>
                {calculatorForm?.shipmentSelect.serviceName
                  .replace('Express', 'Blue bit')
                  .replace('FedEx', 'Purple bit')}
              </p>
            </div>
          </div>
        </div>
        <div className='rounded-xl shadow-xl p-4 bg-white h-30 shadow-sm'>
          <div className='flex h-full'>
            <div className='m-auto'>
              <span className='font-extrabold text-xs uppercase text-black'>
                Fecha de entrega:
              </span>
              <p className='text-xl text-black'>
                {calculatorForm?.shipmentSelect.promiseDate || '----'}
              </p>
            </div>
          </div>
        </div>
        <div className='rounded-xl shadow-xl p-4 bg-white h-30 shadow-sm'>
          <div className='flex h-full'>
            <div className='m-auto'>
              <span className='font-extrabold text-xs uppercase text-black'>
                N° de Paquetes:
              </span>
              <p className='text-xl text-black'>{totalPkt} UND</p>
            </div>
          </div>
        </div>
        <div className='rounded-xl shadow-xl p-4 bg-white h-30 shadow-sm'>
          <div className='flex h-full'>
            <div className='m-auto'>
              <span className='font-extrabold text-xs uppercase text-black'>
                N° de Sobres:
              </span>
              <p className='text-xl text-black'>
                {calculatorForm?.amountOfEnvelope} UND
              </p>
            </div>
          </div>
        </div>
        <div className='rounded-xl shadow-xl p-4 bg-white h-30 shadow-sm align-middle'>
          <div className='flex h-full'>
            <div className='m-auto'>
              <span className='font-extrabold text-xs uppercase text-black'>
                Descuento:
              </span>
              <p className='text-xl text-black'>{profile?.discount} %</p>
            </div>
          </div>
        </div>
        {profile.canSeeQuoteTotalPrice && (
          <div className='rounded-xl shadow-xl p-4 bg-white h-30 shadow-sm align-middle'>
            <div className='flex h-full'>
              <div className='m-auto'>
                <span className='font-extrabold text-xs uppercase text-black'>
                  Precio:
                </span>
                <p className='text-xl text-black'>
                  {' '}
                  {profile.showFinalPriceByService[
                    calculatorForm?.shipmentSelect.carrier.toLowerCase()
                  ]
                    ? `$ ${calculatorForm?.shipmentSelect.clientPrice?.toFixed(
                        2,
                      )} ${calculatorForm?.shipmentSelect.currency}`
                    : '-'}{' '}
                </p>
              </div>
            </div>
          </div>
        )}
        {profile.canSeeQuoteTotalPrice && (
          <div className='rounded-xl shadow-xl p-4 bg-white h-30 shadow-sm align-middle'>
            <div className='flex h-full'>
              <div className='m-auto'>
                <span className='font-extrabold text-xs uppercase text-black'>
                  Precio final:
                </span>
                <div className='relative group'>
                  <p
                    className={`text-xl ${
                      newShipmentData?.priceWithDiscount
                        ? 'text-primary-inabit-red'
                        : ''
                    }`}
                  >
                    {profile?.showFinalPriceByService?.[
                      calculatorForm?.shipmentSelect?.carrier?.toLowerCase()
                    ]
                      ? newShipmentData?.priceWithDiscount
                        ? `$ ${newShipmentData.priceWithDiscount.toFixed(2)} ${
                            newShipmentData?.currency || ''
                          }`
                        : `$ ${
                            calculatorForm?.shipmentSelect.priceWithDiscount?.toFixed(2) ||
                            '0.00'
                          } ${calculatorForm?.shipmentSelect?.currency || ''}`
                      : '-'}
                  </p>
                  {shouldShowHover && (
                    <div className='absolute hidden group-hover:block bg-gray-200 text-xs rounded p-2 shadow-md top-full mt-1'>
                      Anterior: $
                      {calculatorForm?.shipmentSelect.priceWithDiscount?.toFixed(2) || '0.00'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuoteResume;
