import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../commons/reducer';
import { post, get } from '../../utils/api';
import { generateShipmentActions, GENERATE_SHIPMENT } from './reducer';
import Swal from 'sweetalert2'
import paths from '../../config/paths'
import withReactContent from 'sweetalert2-react-content'
import { cleaningNullValues } from '../../utils/functions'
import { searchString } from '../../utils/parser'

const MySwal = withReactContent(Swal)

const getMunicipalityAndStateCode = (data: any[], colonyName: string, postalCode: string, carrier: string) => {
  if (carrier === 'INABIT') return {
    municipality: 'NaN',
    stateCode: 'Na',
  }
  return data.find(e => e.colonyName.match(searchString(colonyName)) && e.postalCode === postalCode)
}

function* generateShipment() {
  yield takeLatest(GENERATE_SHIPMENT.GENERATE_SHIPMENT, function* fn(action: any): any {
    const carrier = action.shipmentControls.carrierSelected.carrier;
    const origData = getMunicipalityAndStateCode(action.shipmentControls.originData, action.shipmentControls.origin.colonyName, action.shipmentControls.origin.postalCode, carrier)
    const destData = getMunicipalityAndStateCode(action.shipmentControls.destData, action.shipmentControls.destination.colonyName, action.shipmentControls.destination.postalCode, carrier)
    delete action.shipmentControls.pktDetails?.shipmentEnvDetails?.sequence
    delete action.shipmentControls.pktDetails?.shipmentEnvDetails?.shpCode
    const body = {
      serviceName: carrier,
      serviceType: action.shipmentControls.carrierSelected.serviceTypeId,
      date: new Date(),
      total: action.shipmentControls.carrierSelected.clientPrice || action.shipmentControls.carrierSelected.amount,
      origin: cleaningNullValues({
        ...action.shipmentControls.origin,
        clientName: action.shipmentControls.origin.clientName,
        stateCode: origData.stateCode,
        population: origData.municipality,
        companyName: action.shipmentControls.origin.companyName,
      }),
      destination: cleaningNullValues({
        ...action.shipmentControls.destination,
        clientName: action.shipmentControls.destination.clientName,
        stateCode: action.shipmentControls.ocurreService && carrier === "EXPRESS" ? action.shipmentControls.destination.stateCode : destData.stateCode,
        population: action.shipmentControls.ocurreService && carrier === "EXPRESS" ? action.shipmentControls.destination.population : destData.municipality,
        companyName: action.shipmentControls.destination.companyName,
      }),
      shipmentDetails: [
        ...action.shipmentControls.pktDetails?.shipmentPktDetails?.map((e: any) => {
          delete e.shpCode
          delete e.sequence
          return { ...e, productIdSAT: e.productIdSAT.split('-')[1], description: e.productIdSAT.split('-')[0] }
        }),
        action.shipmentControls.pktDetails?.shipmentEnvDetails
      ].filter(e => e),
      references: action.shipmentControls.references,
      observations: action.shipmentControls.observations || 'Na',
      acknowledgmentXT: action.shipmentControls.acknowledgmentXT,
      ocurreService: carrier === "EXPRESS" && action.shipmentControls.ocurreService ? JSON.parse(action.shipmentControls.destinationSelected).clave : "Na",
      shopifyOrder: action.shipmentControls.shopifyOrder,
      wooCommerceOrder: action.shipmentControls.wooCommerceOrder,
      pickupOrder: action.shipmentControls.pickupOrder,
      pickupDate: action.shipmentControls.pickupDate,
      isResidency: action.shipmentControls.isResidency,
      pickupStartTime: action.shipmentControls.pickupStartTime,
      pickupEndTime: action.shipmentControls.pickupEndTime,
    };

    try {
      const response = yield post('/shipping/generate-guide', body);
      if (response.error) {
        yield put(generateShipmentActions.generateShipmentFailed({ response: response.errorMsg }));
        MySwal.fire({
          icon: 'error',
          title: `Ooops, tenemos problemas para generar tu guía`,
          text: JSON.parse(response.errorMsg).error.message,
        })
      } else {
        yield put(generateShipmentActions.generateShipmentSuccess({}));
        MySwal.fire({
          icon: 'success',
          title: `Tu guía se ha generado con éxito`,
          text: response.errorMsg,
        })
        action.shipmentControls.history.push(paths.SHIPMENTS);
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}

function* getProductCatalog() {
  yield takeLatest(GENERATE_SHIPMENT.GET_PRODUCT_CATALOG, function* fn(action: any): any {
    let filter = action.filter
    let response
    try {
      if (!filter) {
        response = []
      } else {
        response = yield get(`/shipping/product-catalog${filter ? `?desc=${filter}` : ''}`);
        if (response.total === 0) response = yield get(`/shipping/product-catalog?desc=no existe`)
      }
      yield put(generateShipmentActions.getProductCatalogSucess(response));
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}

function* getPickupAvailability() {
  yield takeLatest(GENERATE_SHIPMENT.GET_PICKUP_AVAILABILITY, function* fn(action: any): any {
    try {
      const { pickupAvailability } = action
      const {shipmentEnvDetails, shipmentPktDetails, pickupDate} = pickupAvailability;
      let totalWeight = 0;
      let totalPackages = 0;
      let biggestPackage = { length: 0, width: 0, height: 0 };

      if (shipmentEnvDetails) {
        totalWeight += shipmentEnvDetails.weight * shipmentEnvDetails.quantity;
        totalPackages += shipmentEnvDetails.quantity;
        biggestPackage = {
          length: shipmentEnvDetails.longShip,
          width: shipmentEnvDetails.widthShip,
          height: shipmentEnvDetails.highShip,
        };
      }

      if (Array.isArray(shipmentPktDetails)) {
        shipmentPktDetails.forEach((pkg) => {
          totalWeight += pkg.weight * pkg.quantity;
          totalPackages += pkg.quantity;

          if (
            pkg.longShip * pkg.widthShip * pkg.highShip >
            biggestPackage.length * biggestPackage.width * biggestPackage.height
          ) {
            biggestPackage = {
              length: pkg.longShip,
              width: pkg.widthShip,
              height: pkg.highShip,
            };
          }
        });
      }
      const body = {
        carrier: pickupAvailability.shipmentSelect.carrier,
        serviceType: pickupAvailability.shipmentSelect.serviceTypeId,
        weight: totalWeight,
        totalPackages,
        pickupDate,
        ...biggestPackage,
        isResidency: pickupAvailability.isResidency ?? false,
        clientAddrOrig: {
          zipCode: pickupAvailability.clientAddrOrig.zipCode,
          colonyName: pickupAvailability.clientAddrOrig.colonyName,
          city: pickupAvailability.origData.find(
            (e: any) => e.colonyName === pickupAvailability.clientAddrOrig.colonyName,
          )?.city,
        },
      }
      const response = yield post('/shipping/check-pickup-availability', body)
      yield put(generateShipmentActions.getPickupAvailabilitySuccess(response));
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}
export default function* saga(): any {
  yield spawn(generateShipment);
  yield spawn(getProductCatalog);
  yield spawn(getPickupAvailability);
}
