/* eslint-disable no-param-reassign */

import { APP } from '../../commons/reducer';
import { createAction, createReducer } from '../../utils/reducer';
// Local constants
const PATH = 'generate_shipment/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const GENERATE_SHIPMENT = {
  GENERATE_SHIPMENT: `${PATH}GENERATE_SHIPMENT`,
  GENERATE_SHIPMENT_SUCCESS: `${PATH}GENERATE_SHIPMENT_SUCCESS`,
  GENERATE_SHIPMENT_FAILED: `${PATH}GENERATE_SHIPMENT_FAILED`,
  GET_PRODUCT_CATALOG: `${PATH}GET_PRODUCT_CATALOG`,
  GET_PRODUCT_CATALOG_SUCCESS: `${PATH}GET_PRODUCT_CATALOG_SUCCESS`,
  GET_PRODUCT_CATALOG_FAILED: `${PATH}GET_PRODUCT_CATALOG_FAILED`,
  GET_PICKUP_AVAILABILITY: `${PATH}GET_PICKUP_AVAILABILITY`,
  GET_PICKUP_AVAILABILITY_SUCCESS: `${PATH}GET_PICKUP_AVAILABILITY_SUCCESS`,
  GET_PICKUP_AVAILABILITY_FAILED: `${PATH}GET_PICKUP_AVAILABILITY_FAILED`,
};

// actions
export const generateShipmentActions = {
  shipmentcontrolsChanged: createAction(CONTROLS_CHANGED, 'shipmentControls'),
  generateShipment: createAction(GENERATE_SHIPMENT.GENERATE_SHIPMENT, 'shipmentControls'),
  generateShipmentSuccess: createAction(GENERATE_SHIPMENT.GENERATE_SHIPMENT_SUCCESS, 'result'),
  generateShipmentFailed: createAction(GENERATE_SHIPMENT.GENERATE_SHIPMENT_FAILED, 'result'),
  getProductCatalog: createAction(GENERATE_SHIPMENT.GET_PRODUCT_CATALOG, 'filter'),
  getProductCatalogSucess: createAction(GENERATE_SHIPMENT.GET_PRODUCT_CATALOG_SUCCESS, 'productCatalog'),
  getProductCatalogFailed: createAction(GENERATE_SHIPMENT.GET_PRODUCT_CATALOG_FAILED, 'productCatalog'),
  getPickupAvailability: createAction(GENERATE_SHIPMENT.GET_PICKUP_AVAILABILITY, 'pickupAvailability'),
  getPickupAvailabilitySuccess: createAction(GENERATE_SHIPMENT.GET_PICKUP_AVAILABILITY_SUCCESS, 'pickupAvailability'),
  getPickupAvailabilityFailed: createAction(GENERATE_SHIPMENT.GET_PICKUP_AVAILABILITY_FAILED, 'pickupAvailability'),
};

const initialState = {
  shipmentControls: {
    step: 1,
    origin: null,
    destination: null,
    errorMessage: '',
    error: false,
    loading: false,
    shipmentSelect: {},
  },
  result: {},
  productCatalog: [],
  pickupAvailability: null
};

const generateShipment = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.shipmentControls = {
      ...state.shipmentControls,
      ...action.shipmentControls,
    };
  },
  [GENERATE_SHIPMENT.GENERATE_SHIPMENT](state: any) {
    state.shipmentControls.loading = true;
  },
  [GENERATE_SHIPMENT.GENERATE_SHIPMENT_SUCCESS](state: any) {
    state.shipmentControls.origNameOfIndividual = '';
    state.shipmentControls.origCompanyName = null;
    state.shipmentControls.origEmail = null;
    state.shipmentControls.origPhone = '';
    state.shipmentControls.origAddressDetail = '';
    state.shipmentControls.origInteriorNumber = '';
    state.shipmentControls.origPostalCod = '';
    state.shipmentControls.origColon = '';
    state.shipmentControls.origCit = '';
    state.shipmentControls.origStat = '';
    state.shipmentControls.origCountr = '';
    state.shipmentControls.originReferenc = '';
    state.shipmentControls.destNameOfIndividual = '';
    state.shipmentControls.destCompanyName = null;
    state.shipmentControls.destEmail = null;
    state.shipmentControls.destPhone = '';
    state.shipmentControls.destAddressDetail = '';
    state.shipmentControls.destInteriorNumber = '';
    state.shipmentControls.destPostalCode = '';
    state.shipmentControls.destColony = '';
    state.shipmentControls.destCity = '';
    state.shipmentControls.destState = '';
    state.shipmentControls.destCountry = '';
    state.shipmentControls.destReference = '';
    state.shipmentControls.errorMessage = '';
    state.shipmentControls.loading = false;
  },
  [GENERATE_SHIPMENT.GENERATE_SHIPMENT_FAILED](state: any) {
    state.shipmentControls.error = true;
    state.shipmentControls.loading = false;
    state.shipmentControls.errorMessage = 'Oops, wrong credentials.';
  },
  [GENERATE_SHIPMENT.GET_PRODUCT_CATALOG](state: any, action: any) {
    state.shipmentControls = {
      ...state.shipmentControls,
      ...action.shipmentControls,
    };
  },
  [GENERATE_SHIPMENT.GET_PRODUCT_CATALOG_SUCCESS](state: any, action: any) {
    const data = action?.productCatalog?.data || []
    state.productCatalog = data.map((item: any) => ({ key: item.key || 'No Items', text: item.description || 'Loading items....', value: item.key || 'No Items' }));
  },
  [GENERATE_SHIPMENT.GET_PRODUCT_CATALOG_FAILED](state: any, action: any) {
    state.productCatalog = []
  },
  [GENERATE_SHIPMENT.GET_PICKUP_AVAILABILITY](state: any, action: any) {
    state.shipmentControls = {
      ...state.shipmentControls,
      ...action.shipmentControls,
    };
  },
  [GENERATE_SHIPMENT.GET_PICKUP_AVAILABILITY_SUCCESS](state: any, action: any) {
    const data = action?.pickupAvailability?.data || {}
    state.pickupAvailability = data;
  },
  [GENERATE_SHIPMENT.GET_PICKUP_AVAILABILITY_FAILED](state: any, action: any) {
    state.pickupAvailability = {}
  },
  [APP.SERVER_FAILED](state: any) {
    state.shipmentControls.loading = false;
  },
});

export default generateShipment;
