import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextField, CircularProgress, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { selectState, actionProps } from '../../../utils/reduxActions';
import { bookAddressActions } from '../../../views/Address/AddressBook/reducer';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface OptionType {
  key: string;
  value: string;
  text: string;
  isFavorite: boolean;
}

interface AddressBookSearcherProps {
  actions?: any;
  profile?: any;
  list?: any;
  favorites?: any;
  searchList?: any;
  isLoadingSearchList?: boolean;
  label?: string;
  onSelectOption?: (value: string) => void;
  listenerValue: any;
  removeFromRegister: () => void;
}

const AddressBookSearcher: React.FC<AddressBookSearcherProps> = ({
  actions,
  favorites,
  searchList,
  label,
  isLoadingSearchList,
  onSelectOption,
  listenerValue,
  removeFromRegister,
}) => {
  const [optionsFormatted, setOptionsFormatted] = useState<OptionType[]>([]);
  const [query, setQuery] = useState('');
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const [showOptions, setShowOptions] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const formatOptions = (optionsToFormat: any[]) =>
    optionsToFormat.map((item: any) => ({
      key: item.id,
      value: JSON.stringify(item),
      text: `${item.nameOfIndividual}, ${item.address.colonyName}, ${item.address.state}, ${item.address.zipCode}`,
      isFavorite: item.isFavorite,
    }));

  useEffect(() => {
    const newOptions = formatOptions([...searchList, ...favorites]);

    const uniqueOptions = Array.from(
      new Map(newOptions.map(option => [option.key, option])).values(),
    );

    setOptionsFormatted(uniqueOptions);
  }, [searchList, favorites]);

  const fetchOptions = useCallback(
    async (searchQuery: string) => {
      try {
        actions.getAddressesSearch(searchQuery);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    },
    [actions],
  );

  useEffect(() => {
    if (!query) return;

    const debounceTimer = setTimeout(() => {
      fetchOptions(query);
    }, 100);

    return () => clearTimeout(debounceTimer);
  }, [query, fetchOptions]);

  useEffect(() => {
    if (!listenerValue) {
      setQuery('');
    }
  }, [listenerValue]);

  const handleOptionClick = (option: OptionType) => {
    setSelectedOption(option);
    setShowOptions(false);
    setQuery(option.text);
    if (onSelectOption) onSelectOption(option.value);
  };

  const onRemoveSelectedAddress = () => {
    removeFromRegister();
    setQuery('');
    setSelectedOption(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const favoriteOptions = optionsFormatted.filter(option => option.isFavorite);
  const searchOptions = optionsFormatted.filter(option => !option.isFavorite);

  return (
    <div
      style={{ position: 'relative' }}
      ref={containerRef}
    >
      <TextField
        autoComplete='off'
        label={label || 'Buscar direcciones'}
        variant='outlined'
        fullWidth
        value={query}
        onChange={e => {
          setQuery(e.target.value);
        }}
        onFocus={() => {
          setShowOptions(true);
        }}
        InputProps={{
          endAdornment: (
            <>
              {isLoadingSearchList && (
                <CircularProgress
                  color='inherit'
                  size={20}
                />
              )}
              {listenerValue && query && (
                <button
                  onClick={onRemoveSelectedAddress}
                  type='button'
                  className='absolute right-2 top-3 -translate-y-1/2 p-2'
                  style={{ marginRight: '8px' }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              )}
            </>
          ),
        }}
      />
      {showOptions && optionsFormatted.length > 0 && (
        <div
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: 99,
            backgroundColor: 'white',
            borderRadius: '4px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          {favoriteOptions.length > 0 && (
            <div className='px-4 pt-4'>
              <p className='font-bold text-xl'>Favoritos</p>
              {favoriteOptions.map(option => (
                <div key={option.key}>
                  <p
                    onClick={() => handleOptionClick(option)}
                    className='cursor-pointer py-3 text-lg'
                  >
                    {option.text}
                  </p>
                  <Divider />
                </div>
              ))}
            </div>
          )}

          {searchOptions.length > 0 && (
            <div className='px-4 pt-4'>
              <p className='font-bold text-xl'>Resultados de búsqueda</p>
              {searchOptions.map(option => (
                <div key={option.key}>
                  <div key={option.key}>
                    <p
                      onClick={() => handleOptionClick(option)}
                      className='cursor-pointer py-3 text-lg'
                    >
                      {option.text}
                    </p>
                    <Divider />
                  </div>
                </div>
              ))}
            </div>
          )}
          {query && isLoadingSearchList && (
            <div className='p-4'>
              <p className='font-bold text-xl'>CARGANDO...</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const withConnect = connect(
  selectState(
    'app.profile',
    'addressBook.favorites',
    'addressBook.searchList',
    'addressBook.isLoadingSearchList',
  ),
  actionProps({ ...bookAddressActions }),
);

export default withRouter(
  withConnect(
    AddressBookSearcher as React.FC<
      AddressBookSearcherProps & RouteComponentProps
    >,
  ),
);
